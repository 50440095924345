.App {
  text-align: center;
}
body{
  background-image: url(/public/assets/background.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.languagePicker{
  display: flex;
  flex-direction: column;
}
.languagePickerField{
  display: flex;
  flex-direction: row;
}

.slide-content{
  display: flex;
  flex-direction: column;
}